// Allows nice sliding of content when OffCanvasMenu is displayed

import styled from 'styled-components'

import media from '../../utils/media'

const ResponsiveContent = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme?.style?.backgroundColor};

  ${media.desktop`
    width: 100vw;
    min-width: 100vw;
  `};

  ${media.phone`
    min-width: auto;
  `};
`

export default ResponsiveContent
