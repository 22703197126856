import styled from 'styled-components'
import media from "../../utils/media";

const MainNav = styled.nav.attrs(() => ({
    role: 'navigation',
    'aria-label': 'Primary',
}))`
  display: flex;
  flex: 1;

  ${media.desktop`
    flex: none;
  `};
`

export default MainNav