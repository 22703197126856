import React from 'react';
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const StyledLink = styled(Link)`
  display: block;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  line-height: 1.2;
  white-space: nowrap;
  margin: 0 10px;
`

const MenuLink = ({to, title}) => {
    return (
        <StyledLink to={to}>{title}</StyledLink>
    );
};

export default MenuLink;