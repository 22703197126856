import React from 'react';
import styled, {withTheme} from "styled-components";
import media from "../../utils/media";
import {dimensions} from "../../utils/dimensions";
import MainNav from "./MainNav";
import {Link} from "react-router-dom";
import MenuLink from "./MenuLink";
import {useConfig} from "../../ConfigContext";

const Wrapper = styled.header`
  animation: 1s ease-out 0s 1 slideInFromTop;
  background-color: ${props => (props.theme.style.headerBackground || 'none')};
  color: ${props => (props.theme.style.headerFontColor || 'none')};
  box-shadow: inset 0 -1px 0 0 rgba(192, 192, 192, 0.5);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1020;
  flex: 0 1;

  ${media.desktop`
    position: static;
  `};
`

const BannerWrapper = styled.div`
  position: relative;
`

const StyledBanner = styled.div.attrs(() => ({
    role: 'banner',
}))`
  box-sizing: border-box;
  color: ${props => props.contentColor};
  display: flex;
  margin: 0 auto;
  max-width: ${dimensions.fullWidth};
  padding: ${dimensions.padding.large} ${dimensions.padding.xlarge};

  svg,
  svg * {
    color: inherit;
  }

  a,
  button {
    color: inherit;
    transition: color 0.125s linear;

    &:hover {
      color: ${props => props.theme.style.ctaColor};
      font-weight: bold;
    }
  }

  ${media.desktop`
    align-items: center;
    margin: 0;
    height: 80px;
    padding: ${dimensions.padding.small} ${dimensions.padding.xlarge};
  `};
`

const LogoWrapper = styled.div`
  font-size: 25px;
  text-transform: uppercase;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: auto;
  max-width: 50%;
  
  ${media.desktop`
    max-width: 100%;
  `};

  & img {
    height: 55px;
  }

  ${media.desktop`
    flex: 1;
  `};

  ${media.tabletPortrait`
    margin-left: auto;
    margin-right: auto;
  `};
`

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-end;
  flex: 1;

  ${media.desktop`
    display: none;
  `};
`

const Header = () => {
    const {pageTitle, menuItems} = useConfig()
    return (
        <Wrapper>
            <BannerWrapper>
                <StyledBanner>
                    <LogoWrapper>
                        <Link to={'/'}>{pageTitle}</Link>
                    </LogoWrapper>
                    <MenuWrapper>
                        <MainNav>
                            {menuItems.map(({to, title}) => <MenuLink to={to} title={title}/>)}
                        </MainNav>
                    </MenuWrapper>
                </StyledBanner>
            </BannerWrapper>
        </Wrapper>
    );
};

export default withTheme(Header);