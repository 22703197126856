import {createContext, useContext, useState} from "react";

const defaultConfig = {
    pageTitle: '',
    menuItems: [],
    theme: null,
}

export const ConfigContext = createContext(defaultConfig)
export const useConfig = () => {
    const {config, setConfig} = useContext(ConfigContext)
    return {...config, setConfig}
}

const ConfigContextProvider = (props) => {
    const [config, setConfig] = useState(defaultConfig)
    
    return (
        <ConfigContext.Provider value={{config, setConfig}}>
            {props.children}
        </ConfigContext.Provider>
    )
}

export default ConfigContextProvider