import React from 'react';
import TextBox from "../TextBox";
import {SocialIcon} from "react-social-icons";
import styled from "styled-components";

const SocialLinksWrapper = styled.div`
    display: flex;
    justify-content: center;
`

const IconWrapper = styled.div`
  margin: 10px;
`

const SocialLinks = ({links, ...props}) => {
    
    return (
        <TextBox {...props}>
            <SocialLinksWrapper>
                {links.map(({url, network}, i) => (
                    <IconWrapper key={`icon-${i}`}>
                        <SocialIcon url={url} network={network}/>
                    </IconWrapper>
                ))}
            </SocialLinksWrapper>
        </TextBox>
    );
};

export default SocialLinks;