import styled from 'styled-components'

import media from '../../utils/media'

const ResponsivePageWrapper = styled.div`
  display: block;
  background: ${props => props.theme.style.backgroundColor};
  color: ${props => props.theme.style.contentColor};
  ${media.desktop`
    display: flex;
  `};
`

ResponsivePageWrapper.defaultProps = {
    theme: {
        style: {
            backgroundColor: '#fff',
            bodyFont: 'Helvetica',
            contentColor: '#000',
            headingFont: 'Georgia',
        },
    },
}

export default ResponsivePageWrapper
