import Root from "./components/Root";
import FontLoader from "./components/FontLoader";
import {Route, Switch} from "react-router-dom";
import PageNotFound from "./components/PageNotFound";
import React from "react";
import Page from "./components/Page";
import Routes from "./Routes";
import ConfigContextProvider from "./ConfigContext";

function App() {
    return (
        <ConfigContextProvider>
            <Root>
                <FontLoader/>
                <Switch>
                    <Route exact component={PageNotFound} path="/404"/>
                    <Route render={() => (
                        <Page>
                            <Routes/>
                        </Page>
                    )}/>
                </Switch>
            </Root>
        </ConfigContextProvider>
    );
}

export default App;
