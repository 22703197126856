import React from 'react';
import {Route, Switch} from "react-router-dom";

import MainPageContainer from "./containers/MainPageContainer";
import AboutContainer from "./containers/AboutContainer";

const Routes = props => {
    return (
        <Switch>
            <Route path="/" exact component={MainPageContainer} />
            <Route path="/about" exact component={AboutContainer} />
        </Switch>
    );
};

Routes.propTypes = {
    
};

export default Routes;