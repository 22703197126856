import React from 'react';
import TextBox from "../../components/TextBox";
import SocialLinks from "../../components/SocialLinks";
import {useConfig} from "../../ConfigContext";

const MainPageContainer = () => {

    const {sections} = useConfig()
    
    return (
        <>
            {sections?.filter(section => section.active).map((section, i) => {
                switch (section.component) {
                    case 'TextBox': 
                        return <TextBox key={`text-box-${i}`} {...section.props}/>
                    case 'SocialLinks': 
                        return <SocialLinks key={`social-links-${i}`} {...section.props}/>
                    default: 
                        return null
                }
            })}
        </>
    );
};

export default MainPageContainer;