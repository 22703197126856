import React from 'react';
import styled from 'styled-components'

const ContentWrapper = styled.div`
`

const MainContent = ({children}) => {
    return (
        <ContentWrapper>
            {children}
        </ContentWrapper>
    );
};

export default MainContent;