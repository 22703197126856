import React, {Component} from 'react';
import PropTypes from "prop-types";

class ErrorBoundary extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired
    }
    
    state = {hasError: false}
    
    componentDidCatch(error, errorInfo) {
        this.setState({hasError: true})
        console.log(error)
        console.log(errorInfo)
    }

    render() {
        if(this.state.hasError) {
            return <h1>Error</h1>
        }
        return this.props.children
    }
}

export default ErrorBoundary;