import { css } from 'styled-components'

export const sizes = {
    largeDesktop: 1440,
    desktop: 1000,
    tablet: 768,
    tabletPortrait: 640,
    phone: 414,
    phoneLandscape: 812,
}

// iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((accumulator, label) => {
    accumulator[label] = (...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `

    return accumulator
}, {})

export default media
