import React from 'react';
import styled from "styled-components";
import {dimensions} from "../../utils/dimensions";
import media from "../../utils/media";
import {useConfig} from "../../ConfigContext";

const Wrapper = styled.footer`
  animation: 1s ease-out 0s 1 slideInFromBottom;
  background-color: ${props => (props.theme.style.footerBackground || 'none')};
  color: ${props => (props.theme.style.footerFontColor || 'none')};
  box-shadow: inset 0 -1px 0 0 rgba(192, 192, 192, 0.5);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1020;
  flex: 0 1;

  ${media.desktop`
    position: static;
  `};
`

const Content = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: ${dimensions.padding.xlarge};
  max-width: ${dimensions.fullWidth};
  min-height: 30px;
  width: 100%;
  margin: auto;
`

const Footer = () => {
    const {footer: {text}} = useConfig()
    return (
        <Wrapper>
            <Content>
                {text}
            </Content>
        </Wrapper>
    );
};

export default Footer;