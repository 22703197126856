import React, {useEffect} from 'react';
import {IntlProvider} from "react-intl";
import {BrowserRouter as Router} from "react-router-dom";
import {ThemeProvider} from "styled-components";
import ErrorBoundary from "../ErrorBoundary";
import {useConfig} from "../../ConfigContext";

const Root = ({children}) => {
    const locale = 'en'
    const {setConfig, theme} = useConfig()
    useEffect(() => {
        (async () => {
            const result = await fetch('/config.json')
            console.log(result)
            const data = await result.json()
            console.log(data)
            setConfig(data)
        })()
    }, [setConfig])
    
    if(!theme) return null
    return (
        <ErrorBoundary>
            <IntlProvider locale={locale}>
                <Router>
                    <ThemeProvider theme={theme}>
                        {children}
                    </ThemeProvider>
                </Router>
            </IntlProvider>
        </ErrorBoundary>
    );
}

export default Root;