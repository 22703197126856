import React from 'react';
import ResponsivePageWrapper from "../ResponsivePageWrapper";
import ResponsiveContent from "../ResponsiveContent";
import {Helmet} from "react-helmet";
import Header from "../Header";
import Footer from "../Footer";
import MainContent from "../MainContent";
import {useConfig} from "../../ConfigContext";

const Page = ({children}) => {
    const {pageTitle} = useConfig();
    return (
        <ResponsivePageWrapper>
            <ResponsiveContent>
                <Helmet defaultTitle={pageTitle} titleTemplate={`%s ~ ${pageTitle}`}/>
                <Header/>
                <MainContent>
                    {children}
                </MainContent>
                <Footer/>
            </ResponsiveContent>
        </ResponsivePageWrapper>
    );
};

export default Page;