// Renders the <link /> tag necessary to load any fonts we're using from google
import { Component } from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import { Helmet } from 'react-helmet'

class FontLoader extends Component {
    static propTypes = {
        theme: PropTypes.object.isRequired,
    }

    static defaultProps = {
        theme: {
            style: { fontPair: ['Georgia', 'Helvetica'], bodyFont: 'Helvetica' },
        },
    }

    render() {
        return (
            <Helmet>
                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
                <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap" rel="stylesheet"/>
            </Helmet>
        )
    }
}

export default withTheme(FontLoader)
