import React from 'react';

const AboutContainer = props => {
    return (
        <div>
            about me
        </div>
    );
};

export default AboutContainer;