import React, {Fragment} from 'react';
import styled from "styled-components";
import Section from "../Section";


const Content = styled.div`
  padding: 35px 0;
  overflow: hidden;
  text-align: center;
  width: 100%;
`

const StyledHeader = styled.h2`
  font-size: 2em;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0.5em 0;
`

const StyledContent = styled.p`
  font-size: 1.5em;
  margin: 0.5em 0;
`

const QuoteWrapper = styled.div`
  background-color: rgba(112, 112, 112, 0.1);
  max-width: 600px;
  margin: 0 auto;
  padding: 10px;
`

const QuoteText = styled.p`
  font-style: italic;
`
const QuoteAuthor = styled.p`
  text-align: right;
  font-size: 0.75em;
`

const StyledImage = styled.img`
  border-radius: 50%;
`

const StyledLine = styled.hr`
  margin: 15px auto 20px;
  padding: 0;
  max-width: 250px;
  border: 0;
  border-top: solid 3px;
  text-align: center;
`

const TextBox = ({title, content, children, image, style, animation, quote}) => {
    
    const lines = [].concat(content)
    
    return (
        <Section backgroundColor={style?.backgroundColor} fontColor={style?.fontColor} animation={animation} centered={!title}>
            <Content>
                {image && <StyledImage src={image?.src}/>}
                {title && (
                    <>
                        <StyledHeader>{title}</StyledHeader>
                        <StyledLine/>
                    </>
                )}
                {lines.map((line, i) => <StyledContent key={`lines-${i}`}>{line}</StyledContent>)}
                {quote?.active &&
                    <QuoteWrapper>
                        <QuoteText>{quote.text}</QuoteText>
                        <QuoteAuthor>{quote.author}</QuoteAuthor>
                    </QuoteWrapper>
                }
                
                {children}
            </Content>
        </Section>
    );
};

export default TextBox;