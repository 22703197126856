import React from 'react';
import styled from "styled-components";
import {dimensions} from "../../utils/dimensions";
import media from "../../utils/media";


const ContentWrapper = styled.div`
  box-sizing: border-box;
  flex: 1 0 auto;
  margin-top: 80px;
  min-height: 300px;
  max-width: ${dimensions.fullWidth};
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0 40px;

  /* styling related to the sidebar */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: ${props => (props.centered ? 'center' : 'start')};
  justify-content: space-between;
  position: relative;

  /* makes children respect the grid even when wrapping */

  /* If position sticky is supported, then the fixed top margin is no longer needed */
  @supports (position: sticky) {
    margin-top: 0px;
  }

  ${media.desktop`
    margin-top: 0;
  `};

  ${media.phone`
    padding: 0;
  `};
`

const StyledSection = styled.section`
  animation: 1s ease-out 0s 1 ${props => (props.animation || 'slideInFromLeft')};
  flex: 1;
  background-color: ${props => (props.backgroundColor || 'none')};
  color: ${props => (props.fontColor || 'none')};
`

const Section = ({children, ...props}) => {
    return (
        <StyledSection {...props}>
            <ContentWrapper centered={props.centered}>
                {children}
            </ContentWrapper>
        </StyledSection>
    );
};

export default Section;