import React from 'react';
import {Helmet} from "react-helmet";

const PageNotFound = () => {
    return (
        <>
            <Helmet title={'Not found'} />
            <h1>Not found</h1>
        </>
    );
};

export default PageNotFound;